import { combineReducers } from "redux"
import FAQReducer from "./FAQReducer"
import PrivacyReducer from "./PrivacyReducer"
import VendorsReducer from './VendorsReducer'
import ContactReducer from "./ContactReducer"
import CategoriesReducer from "./CategoriesReducer"


export default combineReducers({
    FAQ: FAQReducer,
    Privacyy: PrivacyReducer,
    Seller: VendorsReducer,
    ContactUs: ContactReducer,
    Categories: CategoriesReducer
})