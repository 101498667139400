import React from 'react'
import NavBar from '../HomePage/NavBar/NavBar'
import Header from '../HomePage/Header/Header'
import Application from '../HomePage/Application/Application'
import Footer from '../HomePage/Footer/Footer'
import './BrandDetails.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StoreLocation from '../Vendors/StoreLocation'
import mob from '../../Images/mobileNumber.png'
import phone from '../../Images/phoneNumber.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { emptyCategoriesVendor, emptyOneVendor, loadCategoriesVendor, loadOneVendor } from '../../Redux/Actions/ActionVendor'
import ReactLoading from 'react-loading';
import Fade from 'react-reveal/Fade';
import FadeFooter from '../HomePage/Footer/FadeFooter'
import { Helmet } from 'react-helmet';

function BrandDetails() {
    let dispatch = useDispatch();
    let location = useLocation();
    let navigate = useNavigate();
    let id = null;
    if (location.state != null && location.state.id != null) {
        id = location.state.id;
    } else {
        navigate('/error');
    }
    const oneVendor = useSelector(state => state.Seller.oneVendor)
    const loadingOneVen = useSelector(state => state.Seller.loadingOneVen)
    const catStore = useSelector(state => state.Seller.catStore)
    const loadingCatStore = useSelector(state => state.Seller.loadingCatStore)
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadOneVendor(id))
        dispatch(loadCategoriesVendor(id))
        return () => {
            dispatch(emptyOneVendor());
            dispatch(emptyCategoriesVendor())
        }
    }, [])
    const settings = {
        infinite: false,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1084,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 917,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },

        ]
    };
    return (
        <>
            <Helmet>
                <meta name="المنتجات الموجودة ضمن تطبيق هديتي" content="المنتجات الموجودة ضمن تطبيق هديتي ، هو أول تطبيق مرخص ينظم عمليات الإهداء بين المغتربين وأهاليهم في الجمهورية العربية السورية إضافة إلى الإهداء والتسوق للقاطنين في سوريا ويحتوي المتجر على العديد من الميزات والاضافات التي ستغير مفهوم التجارة الالكترونية" />
            </Helmet>
            <NavBar />
            <Header />
            <div className='GeneralContainer1' dir='rtl' >
                <br></br><br></br>
                {
                    loadingOneVen == false ? (
                        oneVendor.map((det) => {
                            return (
                                <div key={det.seller_id} >
                                    <Fade right duration={2000} ><h3 className='titleFaq' >{det.store_name}</h3></Fade>
                                    <div className='more_info_brand' >
                                        <Fade right duration={2000} >
                                            <div className='info_brand' >
                                                <div className='brand_header_flex' >
                                                    <img src={det.seller_profile} alt={det.store_name} title={det.store_name} />
                                                    <h3>{det.store_name}</h3>
                                                </div>
                                                <div className='rest_info' >
                                                    <p>{det.store_description}</p>
                                                </div>
                                                <div className='rest_info' >
                                                    <div className='icon_flex' >
                                                        <i className='fa fa-map-marker' ></i>
                                                        <span>{det.store_city} / {det.store_area} / {det.store_address}  </span>
                                                    </div>
                                                </div>
                                                <div className='rest_info' >
                                                    <div className='icon_flex' >
                                                        <i className='fa fa-envelope' ></i>
                                                        <span>{det.store_email}</span>
                                                    </div>
                                                </div>
                                                <div className='rest_info' >
                                                    <div className='icon_flex' >
                                                        <img src={mob} />
                                                        <span>{det.store_mobile}</span>
                                                    </div>
                                                    <div className='icon_flex' >
                                                        <img src={phone} />
                                                        <span>{det.store_phone}</span>
                                                    </div>
                                                </div>
                                                <br></br>
                                            </div>
                                        </Fade>
                                        <Fade left duration={2000} >
                                            <div className='map_brad' >
                                                <StoreLocation lat={det.store_latitude} lng={det.store_longitude} />
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                            )
                        })
                    ) : <div className='div_loader' ><ReactLoading type="spinningBubbles" color="#6f42c1" height={'7%'} width={'7%'} /></div>
                }

                <br></br><br></br>
                <Fade right duration={2000} ><h3 className='titleFaq' >أصناف المتجر</h3></Fade>
                {
                    loadingCatStore == false ? (
                        catStore.map((cat) => {
                            return (
                                <div key={cat.id} >
                                    <Fade bottom duration={2000} >
                                        <div className='card_details' >
                                            <div className='detailsTop' >
                                                <img src={cat.image} alt={cat.name} title={cat.name} />
                                                <div>
                                                    <span className='tit_det' >{cat.name}</span><br></br>
                                                    <span className='span_det' >فيما يلي منتجات {cat.name}</span>
                                                </div>
                                            </div>
                                            <div className='detailsSlider' >
                                                <Slider {...settings} className='silder11'>
                                                    {
                                                        cat.product.map((pro) => {
                                                            return (
                                                                <div className='oneSeller' >
                                                                    <div className='center_img' >
                                                                        <div className='seller_img' >
                                                                            <img src={pro.image} alt={pro.name} title={pro.name} />
                                                                        </div>
                                                                    </div>
                                                                    <h3>{pro.name}</h3>
                                                                    {
                                                                        pro.variants[0].special_price != "0" ? (
                                                                            <>
                                                                                <s className='color_red' ><h3 className='price_product color_red' >{pro.variants[0].price}</h3></s>
                                                                                <h3 className='price_product color_yellow' >  {pro.variants[0].special_price} <span>s.p</span></h3>
                                                                            </>
                                                                        ) : <h3 className='price_product color_yellow' >  {pro.variants[0].price} <span>s.p</span></h3>
                                                                    }

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Slider>

                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            )
                        })) : <div className='div_loader' ><ReactLoading type="spinningBubbles" color="#6f42c1" height={'7%'} width={'7%'} /></div>
                }


            </div>
            <FadeFooter />
        </>
    )
}

export default BrandDetails