import * as types from "../ActionType";
import axios from "axios";

const getVendors = (Vendors) => {
    return ({
        type: types.GET_ALL_VENDORS,
        payload: Vendors
    })
}

const getSearchVendor = (Vendors) => {
    return ({
        type: types.SEARCH_VENDORS,
        payload: Vendors
    })
}

const emptyGeneral = (empty,typee) => {
    return ({
        type: typee,
        payload: empty
    })
}
const getOneVendor = (oneVendor) => {
    return ({
        type: types.GET_VENDORS_DETAILS,
        payload: oneVendor
    })
}

const getCatVendor = (catStore) => {
    return ({
        type: types.GET_CATEGORY_STORE,
        payload: catStore
    })
}

export const loadVendors = () => {
    return function (dispatch) {
        axios.post("https://hadyati.sy/web/v1/api/get_sellers").then((res) => {
            //console.log(res);
            dispatch(getVendors(res.data.data))
        }).catch((err) => console.log(err))
    }
}

export const SearchVendors = (search) => {
    return function (dispatch) {
        dispatch({ type: types.LOAD_SEARCH, payload: true });
        //console.log(search)
        const Dataa = new FormData();
        Dataa.append('search', search);
        axios.post(`https://hadyati.sy/web/v1/api/get_sellers`, Dataa).then((res) => {
            console.log(res);
            dispatch(getSearchVendor(res.data.data))
            dispatch({ type: types.LOAD_SEARCH, payload: false });

        }).catch((err) => console.log(err))
    }
}



export const emptyVendors = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch(emptyGeneral(emptyy , types.EMPTY_VENDORS))
    }
}

export const loadOneVendor = (seller_id) => {
    return function (dispatch) {
        const Dataa = new FormData();
        Dataa.append('seller_id', seller_id);
        axios.post(`https://hadyati.sy/web/v1/api/get_sellers`, Dataa ).then((res) => {
            //console.log(res);
            dispatch(getOneVendor(res.data.data))
        }).catch((err) => console.log(err))
    }
}

export const loadCategoriesVendor = (seller_id) => {
    return function (dispatch) {
        const Dataa = new FormData();
        Dataa.append('seller_id', seller_id);
        axios.post(`https://hadyati.sy/web/v1/api/get_category_by_seller_id`, Dataa ).then((res) => {
            //console.log(res);
            dispatch(getCatVendor(res.data.category))
        }).catch((err) => console.log(err))
    }
}

export const emptyOneVendor = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch(emptyGeneral(emptyy , types.EMPTY_VENDORS_DETAILS))
    }
}
export const emptyCategoriesVendor = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch(emptyGeneral(emptyy , types.EMPTY_CATEGORY_STORE))
    }
}