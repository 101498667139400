import React, { useEffect, useState } from 'react'
import NavBar from '../HomePage/NavBar/NavBar'
import Header from '../HomePage/Header/Header'
import Application from '../HomePage/Application/Application'
import Footer from '../HomePage/Footer/Footer'
import './Privacy.css'
import { useDispatch, useSelector } from "react-redux"
import SinglePagenation from '../Pagination/SinglePagenation'
import { loadPrivacy } from '../../Redux/Actions/ActionPrivacy'
import ReactLoading from 'react-loading';
import { Helmet } from 'react-helmet';
import priv from '../../Images/safety.png'
import Fade from 'react-reveal/Fade';
import FadeFooter from '../HomePage/Footer/FadeFooter'

function Privacy() {
    let dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState(0);
    const Privacy = useSelector(state => state.Privacyy.Privacy)
    const loadingPrivacy = useSelector(state => state.Privacyy.loadingPrivacy)
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadPrivacy());
    }, [])
    const PrivacyPerPage = 7;
    const pagesVisited = pageNumber * PrivacyPerPage;
    const displayPrivacy = Privacy.slice(
        pagesVisited,
        pagesVisited + PrivacyPerPage
    );

    const pageCount = Math.ceil(Privacy.length / PrivacyPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    return (
        <>
            <Helmet>
                <meta name="سياسة الخصوصية لتطبيق هديتي" content="سياسة الخصوصية لتطبيق هديتي ، هو أول تطبيق مرخص ينظم عمليات الإهداء بين المغتربين وأهاليهم في الجمهورية العربية السورية إضافة إلى الإهداء والتسوق للقاطنين في سوريا ويحتوي المتجر على العديد من الميزات والاضافات التي ستغير مفهوم التجارة الالكترونية" />
            </Helmet>
            <NavBar />
            <Header />
            <div className='GeneralContainer1' dir='rtl' >
                <br></br><br></br>
                <Fade right duration={2000} ><h3 className='titleFaq' >السياسة والخصوصية</h3></Fade>
                <div className='cards' >
                    {
                        loadingPrivacy == false ? (
                            displayPrivacy.map((pri) => {
                                return (
                                    <Fade bottom duration={2000} key={pri.id} >
                                        <div className='onePrivacy'  >
                                            <div className='privacy_head' >
                                                <i className='fas fa-shield-alt' ></i>
                                                <span className='onePrivacy_tit' >{pri.title}</span>
                                            </div>
                                            <h5>{pri.sub_title}</h5>
                                            <p>{pri.discription}</p>
                                            <img src={priv} />
                                        </div>
                                    </Fade>
                                )
                            })
                        ) : <div className='div_loader' ><ReactLoading type="spinningBubbles" color="#6f42c1" height={'7%'} width={'7%'} /></div>
                    }
                </div>
                <SinglePagenation pageCount={pageCount} changePage={changePage} />
                <br></br><br></br>
            </div>
            <FadeFooter />
        </>
    )
}

export default Privacy