import * as types from "../ActionType";

const initState = {
    Category: [],
    loadingCategory : true
}

const CategoriesReducer = (state = initState , action) => {
    switch(action.type){
        case types.GET_ALL_CATEGORIES:
            return {...state , Category: action.payload , loadingCategory: false}
        default:
            return state;
    }
};

export default CategoriesReducer;