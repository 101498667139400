import * as types from "../ActionType";
import axios from "axios"; 

const getContact = (Contact) => {
    return ({
        type: types.GET_ALL_CONTACT,
        payload: Contact
    })
}


export const loadContact = () => {
    return function (dispatch) {
        axios.get("https://hadyati.sy/web/v1/api/contact_us").then((res) => {
            //console.log(res);
            dispatch(getContact(res.data.data))
        }).catch((err) => console.log(err))
    }
}
