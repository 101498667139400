import React from "react";
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {MapWithAMarker} from './MapWithAMarker'


function StoreLocation({ lat, lng }) {
  return (
      <MapWithAMarker
        lat={parseFloat(lat)}
        lng={parseFloat(lng)}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBTe3zscwtZeS24s2izez3M1aj7LTJdUx0`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
  );
}

export default StoreLocation;