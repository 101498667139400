import React from 'react'
import Application from '../Application/Application'
import Footer from './Footer'
import Fade from 'react-reveal/Fade';

function FadeFooter() {
    return (
        <Fade bottom duration={2000} >
            <div>
                <Application />
                <Footer />
            </div>
        </Fade>
    )
}

export default FadeFooter