import React, { useEffect } from 'react'
import NavBar from '../HomePage/NavBar/NavBar'
import Header from '../HomePage/Header/Header'
import Application from '../HomePage/Application/Application'
import Footer from '../HomePage/Footer/Footer'
import './Contact.css'
import phone from '../../Images/contact-us.png'
import { useDispatch, useSelector } from "react-redux"
import { loadContact } from '../../Redux/Actions/ActionContact'
import { MapWithAMarker } from '../Vendors/MapWithAMarker'
import ReactLoading from 'react-loading';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';
import FadeFooter from '../HomePage/Footer/FadeFooter'

function Contact() {
    let dispatch = useDispatch();
    const Contact = useSelector(state => state.ContactUs.Contact)
    const loadingContact = useSelector(state => state.ContactUs.loadingContact)
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadContact())
    }, [])
    return (
        <>
            <Helmet>
                <meta name="طرق التواصل مع فريق دعم هديتي" content="طرق التواصل مع فريق هديتي ، هو أول تطبيق مرخص ينظم عمليات الإهداء بين المغتربين وأهاليهم في الجمهورية العربية السورية إضافة إلى الإهداء والتسوق للقاطنين في سوريا ويحتوي المتجر على العديد من الميزات والاضافات التي ستغير مفهوم التجارة الالكترونية" />
            </Helmet>
            <NavBar />
            <Header />
            <div className='GeneralContainer' dir='rtl' >
                <br></br><br></br>
                <div className='GeneralRow' >
                    {
                        loadingContact == false ? (
                            Contact.map((con) => {
                                return (
                                    <>
                                        <Fade right duration={2000} key={con.id} >
                                            <div className='contact_info' >
                                                <div className='contact_head' >
                                                    <img src={phone} />
                                                    <h3>تواصل معنا</h3>
                                                </div>
                                                <span className='contact_tit' >{con.title}</span>
                                                <h4><i className='fa fa-clock' ></i>{con.sub_title}</h4>
                                                <div className='div_line'>
                                                    <div >
                                                        <span >{con.discription}</span><br></br>
                                                    </div>
                                                </div>
                                                <h4 className='margin_min' ><i className='fa fa-phone' ></i> أرقام التواصل </h4>
                                                <div className='div_line'>
                                                    <span>{con.phone1}</span><br></br>
                                                    <span>{con.phone2}</span><br></br>
                                                </div><br></br>
                                                <span className='note' >{con.note}</span>
                                            </div>
                                        </Fade>
                                        <Fade left duration={2000} >
                                            <div className='contact_map' >
                                                <MapWithAMarker
                                                    lat={parseFloat(con.latitude)}
                                                    lng={parseFloat(con.longitude)}
                                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBTe3zscwtZeS24s2izez3M1aj7LTJdUx0`}
                                                    loadingElement={<div style={{ height: `100%` }} />}
                                                    containerElement={<div style={{ height: `100%` }} />}
                                                    mapElement={<div style={{ height: `100%` }} />}
                                                />
                                            </div>
                                        </Fade>
                                    </>
                                )
                            })) : <div className='div_loader' ><ReactLoading type="spinningBubbles" color="#6f42c1" height={'7%'} width={'7%'} /></div>
                    }
                </div>
                <br></br><br></br>
            </div>
            <FadeFooter />
        </>
    )
}

export default Contact