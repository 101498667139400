import React from 'react'
import './Cards.css'
import faq from '../../../Images/faq.png'
import privacy from '../../../Images/compliant.png'
import term from '../../../Images/agreement.png'
import contact from '../../../Images/contact-us.png'
import vendor from '../../../Images/cashier.png'
import { useNavigate } from 'react-router-dom'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

function Cards() {
    let navigate = useNavigate()
    const RoutToFaq = () => {
        navigate('/faq')
    }
    const RoutToPrivacy = () => {
        navigate('/privacy')
    }
    const RoutToterms = () => {
        navigate('/terms')
    }
    const RoutToContact = () => {
        navigate('/contact')
    }
    const RoutToVendor = () => {
        navigate('/brands')
    }
    return (
        <div>
            <div className='GeneralContainer1 ' dir='rtl' >
                <Fade right duration={2000}  ><h3 className='more_know' >تعرف حولنا اكثر</h3></Fade>
                <div className='cards align_item' >
                    <Zoom duration={2000}>
                        <div className='oneCard border_blue' onClick={() => RoutToFaq()} >
                            <h3>الاسئلة الاكثر شيوعاً</h3>
                            <span>انقر هنا للتعرف على الاسئلة الشائعة</span>
                            <div className='div_img' >
                                <img src={faq} />
                            </div>
                        </div>
                    </Zoom>
                    <Zoom duration={2000}>
                        <div className='oneCard border_pink' onClick={() => RoutToPrivacy()} >
                            <h3>سياسة الخصوصية</h3>
                            <span>انقر هنا للتعرف على سياسة الخصوصية</span>
                            <div className='div_img' >
                                <img src={privacy} />
                            </div>
                        </div>
                    </Zoom>
                    <Zoom duration={2000}>
                        <div className='oneCard border_green' onClick={() => RoutToterms()} >
                            <h3>الاحكام والشروط</h3>
                            <span>انقر هنا للتعرف على الاحكام و الشروط</span>
                            <div className='div_img' >
                                <img src={term} />
                            </div>
                        </div>
                    </Zoom>
                    <Zoom duration={2000}>
                        <div className='oneCard border_orang' onClick={() => RoutToContact()} >
                            <h3>تواصل معنا</h3>
                            <span>انقر هنا للتعرف على طرق التواصل </span>
                            <div className='div_img' >
                                <img src={contact} />
                            </div>
                        </div>
                    </Zoom>
                    <Zoom duration={2000}>
                        <div className='oneCard border_yellow' onClick={() => RoutToVendor()} >
                            <h3>البائعون</h3>
                            <span>انقر هنا للتعرف على المتاجر المتوافر لدينا </span>
                            <div className='div_img' >
                                <img src={vendor} />
                            </div>
                        </div>
                    </Zoom>
                </div>
            </div>
        </div>
    )
}

export default Cards