import React, { useEffect } from "react";
import "./Application.css";
import app from "../../../Images/app2.png";
import google from "../../../Images/google_play.svg";
import ios from '../../../Images/app_store.svg'


export default function Application() {
  return (

    <div className="application">
      <div className="inner_app">
        <div className="application_left">
          <img src={app} alt="تطبيق هديتي" title="تطبيق هديتي" />
        </div>


        <div className="application_right" dir="rtl" >
          <h2>
            احصل على وصول سهل إلى جميع الميزات باستخدام تطبيق هديتي
          </h2>
          <div className='div_download' >
            <img src={ios} />
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.peaklink.hadyaty" ><img src={google} /></a>
          </div>
        </div>

      </div>
    </div>


  );
}
