import * as types from "../ActionType";

const initState = {
    Faqs: [],
    loadingFaq : true
}

const FAQReducer = (state = initState , action) => {
    switch(action.type){
        case types.GET_ALL_FAQS:
            return {...state , Faqs: action.payload , loadingFaq: false}
        default:
            return state;
    }
};

export default FAQReducer;
