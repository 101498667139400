import React, { useEffect } from 'react'
import './SliderVen.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux"
import ReactLoading from 'react-loading';
import { loadCategory } from '../../../Redux/Actions/ActionCategories';
import Fade from 'react-reveal/Fade';

function SliderVen() {
    let dispatch = useDispatch();
    const Category = useSelector(state => state.Categories.Category)
    const loadingCategory = useSelector(state => state.Categories.loadingCategory)
    useEffect(() => {
        dispatch(loadCategory())
    }, [])
    const settings = {
        infinite: false,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1084,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 917,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },

        ]
    };
    return (
        <div className='GeneralContainer1' >
            <Fade right duration={2000} ><h3 className='more_know' style={{ textAlign: "right" }} >الاصناف الموجودة لدينا</h3></Fade>
            <Fade bottom duration={2000} >
                <div>
                    {
                        loadingCategory == false ? (
                            <Slider {...settings} className='silder11'>
                                {
                                    Category.map((cat) => {
                                        return (
                                            <div className='oneSeller' key={cat.id} >
                                                <div className='center_img' >
                                                    <div className='seller_img' >
                                                        <img src={cat.image} atl={cat.name} title={cat.name} />
                                                    </div>
                                                </div>
                                                <h3>{cat.name}</h3>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        ) : <div className='div_loader' ><ReactLoading type="spinningBubbles" color="#6f42c1" height={'7%'} width={'7%'} /></div>
                    }
                </div>
            </Fade>
        </div>
    )
}

export default SliderVen