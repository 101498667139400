import React, { useState, useEffect } from "react";
import './NavBar.css'
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link'
import logo from '.././../../Images/logoH.png'

const NavBar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
      const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        if (scrollTop > 110) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };
    return (
        <header className={isScrolled? "header scroll" : "header unScroll"} >
            <input type="checkbox" name="" id="toggler" />
            <label htmlFor="toggler" className="fa fa-bars menu"></label>
            <div className={isScrolled? "navbar navbarScroll": "navbar navbarunScroll"}>
                <Link className={isScrolled? "navbar_linknew navbar_linknewunScroll": "navbar_linknew navbar_linknewScroll"} to="/" >الرئيسية</Link>
                <a className={isScrolled? "navbar_linknew navbar_linknewunScroll": "navbar_linknew navbar_linknewScroll"}  href="https://drive.google.com/u/0/uc?id=1nZPZduKAZ8IBcvciYcm5udNeaQ4ixKeT&export=download" target="_blank" >تحميل مباشر </a>
                <a className={isScrolled? "navbar_linknew navbar_linknewunScroll": "navbar_linknew navbar_linknewScroll"}  target="_blank" href="https://hadyati.sy/seller/auth/sign_up"  >انضم لنا ك بائع </a>
                <Link  className={isScrolled? "navbar_linknew navbar_linknewunScroll": "navbar_linknew navbar_linknewScroll"} smooth={true.toString()} to="/brands">جميع البائعون</Link>
            </div>
            <img src={logo} className={isScrolled ? "logo logoUnScroll": "logo logoScroll" } />
        </header>
    )
}

export default NavBar;