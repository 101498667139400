export const GET_ALL_FAQS = "GET_ALL_FAQS";
export const GET_ALL_PRIVACY = "GET_ALL_PRIVACY";
export const GET_ALL_VENDORS = "GET_ALL_VENDORS";
export const GET_ALL_CONTACT = "GET_ALL_CONTACT";
export const GET_ALL_CATEGORIES= "GET_ALL_CATEGORIES";
export const GET_ALL_TERMS= "GET_ALL_TERMS";
export const SEARCH_VENDORS= "SEARCH_VENDORS";
export const LOAD_SEARCH= "LOAD_SEARCH";
export const EMPTY_VENDORS= "EMPTY_VENDORS";
export const GET_VENDORS_DETAILS= "GET_VENDORS_DETAILS";
export const GET_CATEGORY_STORE= "GET_CATEGORY_STORE";
export const EMPTY_VENDORS_DETAILS = "EMPTY_VENDORS_DETAILS";
export const EMPTY_CATEGORY_STORE = "EMPTY_CATEGORY_STORE";
