import React, { useEffect, useState } from 'react'
import './Faq.css'
import ques from '../../Images/question-mark.png'
import NavBar from '../HomePage/NavBar/NavBar'
import Application from '../HomePage/Application/Application'
import Footer from '../HomePage/Footer/Footer'
import Header from '../HomePage/Header/Header'
import { useDispatch, useSelector } from "react-redux"
import { loadFaqs } from '../../Redux/Actions/ActionFAQ'
import SinglePagenation from '../Pagination/SinglePagenation'
import ReactLoading from 'react-loading';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';
import FadeFooter from '../HomePage/Footer/FadeFooter'

function Faq() {
    let dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState(0);
    const Faqs = useSelector(state => state.FAQ.Faqs)
    const loadingFaq = useSelector(state => state.FAQ.loadingFaq)
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadFaqs())
    }, [])
    const FAQPerPage = 10;
    const pagesVisited = pageNumber * FAQPerPage;
    const displayFAQ = Faqs.slice(
        pagesVisited,
        pagesVisited + FAQPerPage
    );

    const pageCount = Math.ceil(Faqs.length / FAQPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    return (
        <>
            <Helmet>
                <meta name="الاسئلة الشائعة حول تطبيق هديتي" content="الاسئلة الاكثر شيوعاً حول تطبيق هديتي ، هو أول تطبيق مرخص ينظم عمليات الإهداء بين المغتربين وأهاليهم في الجمهورية العربية السورية إضافة إلى الإهداء والتسوق للقاطنين في سوريا ويحتوي المتجر على العديد من الميزات والاضافات التي ستغير مفهوم التجارة الالكترونية" />
            </Helmet>
            <NavBar />
            <Header />
            <div className='GeneralContainer' dir='rtl' >
                <br></br><br></br>
                <Fade right duration={2000} ><h3 className='titleFaq' >الاسئلة الشائعة</h3></Fade>
                <div className='cards' >
                    {
                        loadingFaq == false ? (
                            displayFAQ.map((faq) => {
                                return (
                                    <Fade right duration={2000} key={faq.id} >
                                        <div className='oneFaq'  >
                                            <img src={ques} />
                                            <div className='ques_info' >
                                                <span className='question' >{faq.question}</span><br></br><br></br>
                                                <span className='answer' >{faq.answer}</span>
                                            </div>
                                        </div>
                                    </Fade>
                                )
                            })

                        ) : <div className='div_loader' ><ReactLoading type="spinningBubbles" color="#6f42c1" height={'7%'} width={'7%'} /></div>
                    }
                </div>
                <SinglePagenation pageCount={pageCount} changePage={changePage} />
                <br></br><br></br>
            </div>
            <FadeFooter />
        </>
    )
}

export default Faq