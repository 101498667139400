import React, { useEffect, useState } from 'react'
import NavBar from '../HomePage/NavBar/NavBar'
import Header from '../HomePage/Header/Header'
import Application from '../HomePage/Application/Application'
import Footer from '../HomePage/Footer/Footer'
import '../Privacy/Privacy.css'
import { useDispatch, useSelector } from "react-redux"
import SinglePagenation from '../Pagination/SinglePagenation'
import ReactLoading from 'react-loading';
import { Helmet } from 'react-helmet';
import priv from '../../Images/safety.png'
import { loadTerms } from '../../Redux/Actions/ActionPrivacy'
import Fade from 'react-reveal/Fade';
import FadeFooter from '../HomePage/Footer/FadeFooter'

function Terms() {
    let dispatch = useDispatch();
    const [pageNumber, setPageNumber] = useState(0);
    const Terms = useSelector(state => state.Privacyy.Terms)
    const loadingterms = useSelector(state => state.Privacyy.loadingterms)
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadTerms());
    }, [])
    const termsPerPage = 7;
    const pagesVisited = pageNumber * termsPerPage;
    const displayterms = Terms.slice(
        pagesVisited,
        pagesVisited + termsPerPage
    );

    const pageCount = Math.ceil(Terms.length / termsPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    return (
        <>
            <Helmet>
                <meta name=" الاحكام والشروط لتطبيق هديتي" content="سياسة الخصوصية لتطبيق هديتي ، هو أول تطبيق مرخص ينظم عمليات الإهداء بين المغتربين وأهاليهم في الجمهورية العربية السورية إضافة إلى الإهداء والتسوق للقاطنين في سوريا ويحتوي المتجر على العديد من الميزات والاضافات التي ستغير مفهوم التجارة الالكترونية" />
            </Helmet>
            <NavBar />
            <Header />
            <div className='GeneralContainer1' dir='rtl' >
                <br></br><br></br>
                <Fade right duration={2000} ><h3 className='titleFaq' >الأحكام والشروط</h3></Fade>
                <div className='cards' >
                    {
                        loadingterms == false ? (
                            displayterms.map((pri) => {
                                return (
                                    <Fade bottom duration={2000} key={pri.id} >
                                        <div className='onePrivacy'  >
                                            <div className='privacy_head' >
                                                <i className='fas fa-shield-alt' ></i>
                                                {
                                                    pri.title == "" ? (
                                                        <span className='onePrivacy_tit' >العنوان</span>
                                                    ) : <span className='onePrivacy_tit' >{pri.title}</span>
                                                }
                                            </div>
                                            <h5>{pri.sub_title}</h5>
                                            <p>{pri.discripton}</p>
                                            <img src={priv} />
                                        </div>
                                    </Fade>
                                )
                            })
                        ) : <div className='div_loader' ><ReactLoading type="spinningBubbles" color="#6f42c1" height={'7%'} width={'7%'} /></div>
                    }
                </div>
                <SinglePagenation pageCount={pageCount} changePage={changePage} />
                <br></br><br></br>
            </div>
            <FadeFooter />
        </>
    )
}

export default Terms