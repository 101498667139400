import * as types from "../ActionType";
import axios from "axios"; 

const getPrivacy = (Privacy) => {
    return ({
        type: types.GET_ALL_PRIVACY,
        payload: Privacy
    })
}

const getTerms = (Terms) => {
    return ({
        type: types.GET_ALL_TERMS,
        payload: Terms
    })
}


export const loadPrivacy = () => {
    return function (dispatch) {
        axios.get("https://hadyati.sy/web/v1/api/privacy_policy").then((res) => {
            //console.log(res);
            dispatch(getPrivacy(res.data.data))
        }).catch((err) => console.log(err))
    }
}

export const loadTerms = () => {
    return function (dispatch) {
        axios.get("https://hadyati.sy/web/v1/api/terms_condition").then((res) => {
            //console.log(res);
            dispatch(getTerms(res.data.data))
        }).catch((err) => console.log(err))
    }
}
