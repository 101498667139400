import * as types from "../ActionType";
import axios from "axios"; 

const getCategory = (Category) => {
    return ({
        type: types.GET_ALL_CATEGORIES,
        payload: Category
    })
}


export const loadCategory = () => {
    return function (dispatch) {
        axios.get("https://hadyati.sy/web/v1/api/get_categories").then((res) => {
            //console.log(res);
            dispatch(getCategory(res.data.data))
        }).catch((err) => console.log(err))
    }
}
