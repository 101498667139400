import React, { useEffect } from 'react'
import NavBar from './NavBar/NavBar'
import Header from './Header/Header'
import AboutUs from './AboutUs/AboutUs'
import Cards from './Cards/Cards'
import SliderVen from './SliderVen/SliderVen'
import { Helmet } from 'react-helmet';
import FadeFooter from './Footer/FadeFooter'

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <Helmet>
        <title>تطبيق هديتي</title>
        <meta name="تطبيق هديتي Hadyati" content="اهدي واتسوق على باب بيتك ،هو أول تطبيق مرخص ينظم عمليات الإهداء بين المغتربين وأهاليهم في الجمهورية العربية السورية إضافة إلى الإهداء والتسوق للقاطنين في سوريا ويحتوي المتجر على العديد من الميزات والاضافات التي ستغير مفهوم التجارة الالكترونية" />
      </Helmet>
      <NavBar />
      <Header />
      <AboutUs />
      <SliderVen />
      <Cards />
      <FadeFooter />
    </div>
  )
}

export default Home