import React, { useEffect, useState } from 'react'
import NavBar from '../HomePage/NavBar/NavBar'
import Header from '../HomePage/Header/Header'
import './Vendors.css'
import Stars from './Stars'
import { useDispatch, useSelector } from "react-redux"
import SinglePagenation from '../Pagination/SinglePagenation'
import { SearchVendors, emptyVendors, loadVendors } from '../../Redux/Actions/ActionVendor'
import ReactLoading from 'react-loading';
import { Helmet } from 'react-helmet';
import noResult from '../../Images/noResult.png'
import { useNavigate } from 'react-router-dom'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import FadeFooter from '../HomePage/Footer/FadeFooter'

function Vendors() {
    let dispatch = useDispatch();
    let navigate = useNavigate()
    const [pageNumber, setPageNumber] = useState(0);
    const Vendors = useSelector(state => state.Seller.Vendors)
    const [records, setRecords] = useState([]);
    const loadingVendors = useSelector(state => state.Seller.loadingVendors)
    const loadSearch = useSelector(state => state.Seller.loadSearch)
    const [SearchVendor, setSearchVendor] = useState('');
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadVendors())
    }, [])
    useEffect(() => {
        setRecords(Vendors)
    }, [Vendors])
    const SellerPerPage = 12;
    const pagesVisited = pageNumber * SellerPerPage;
    const displaySeller = records.slice(
        pagesVisited,
        pagesVisited + SellerPerPage
    );

    const pageCount = Math.ceil(records.length / SellerPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const RoutToDetails = (idd,name) => {
        navigate(`/brands/${name}`, {
            state: {
                id: idd
            }
        });
    }
    return (
        <>
            <Helmet>
                <meta name="المتاجر البائعون في تطبيق هديتي " content="المتاجر البائعون في تطبيق هديتي ،هو أول تطبيق مرخص ينظم عمليات الإهداء بين المغتربين وأهاليهم في الجمهورية العربية السورية إضافة إلى الإهداء والتسوق للقاطنين في سوريا ويحتوي المتجر على العديد من الميزات والاضافات التي ستغير مفهوم التجارة الالكترونية" />
            </Helmet>
            <NavBar />
            <Header />
            <div className='GeneralContainer' dir='rtl' >
                <br></br><br></br>
                <Fade right duration={2000} >
                    <h3 className='titleFaq' >البائعون</h3>
                    <div className='search_ven'>
                        <form className='with_input' onSubmit={(e) => { e.preventDefault(); dispatch(SearchVendors(SearchVendor)) }} >
                            {
                                loadSearch ? <ReactLoading type="spinningBubbles" color="#6f42c1" height={'7%'} width={'7%'} /> :
                                    <i className='fa fa-search iconSearch' onClick={(e) => { e.preventDefault(); dispatch(SearchVendors(SearchVendor)) }} ></i>
                            }
                            <input value={SearchVendor} placeholder='ابحث عن المتجر الذي تريده ...' onChange={(e) => setSearchVendor(e.target.value)} />
                        </form>
                        <i className='fa fa-close icon_close' onClick={() => { setSearchVendor(""); dispatch(emptyVendors()); dispatch(loadVendors()) }} ></i>
                    </div>
                </Fade>
                <div className='GeneralRowVen align_item' >
                    {
                        loadingVendors == false ? (
                            displaySeller.map((dd) => {
                                return (
                                    <Zoom duration={2000} key={dd.seller_id} >
                                        <div className='newVen' >
                                            <img src={dd.seller_profile} alt={dd.store_name} title={dd.store_name} />
                                            <div>
                                                <div className='info_head11' >
                                                    <h3>{dd.store_name}</h3>
                                                    <div className='stars_ven' >
                                                        <Stars num={parseInt(dd.seller_rating)} />
                                                    </div>
                                                </div>
                                                <div className='det_ven' ><button onClick={() => RoutToDetails(dd.seller_id , dd.store_name)} >
                                                    <i className='fa fa-exclamation-circle' ></i>
                                                    التفاصيل
                                                </button></div>
                                            </div>
                                        </div>
                                    </Zoom>
                                )
                            })
                        ) : <div className='div_loader' ><ReactLoading type="spinningBubbles" color="#6f42c1" height={'7%'} width={'7%'} /></div>
                    }
                    {
                        (loadingVendors == false && displaySeller.length == 0) && (
                            <div className='img_noresult' >
                                <img src={noResult} />
                                <h3>لا يوجد نتائج</h3>
                            </div>
                        )
                    }

                </div>
                <SinglePagenation pageCount={pageCount} changePage={changePage} />
                <br></br><br></br>
            </div>
            <FadeFooter />
        </>
    )
}
export default Vendors;
