import * as types from "../ActionType";

const initState = {
    Vendors: [],
    loadingVendors: true,
    loadSearch: false,
    oneVendor: [],
    loadingOneVen: true,
    catStore: [],
    loadingCatStore: true
}

const VendorsReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_ALL_VENDORS:
            return { ...state, Vendors: action.payload, loadingVendors: false }
        case types.SEARCH_VENDORS:
            return { ...state, Vendors: action.payload, loadingVendors: false }
        case types.LOAD_SEARCH:
            return { ...state, loadSearch: action.payload }
        case types.EMPTY_VENDORS:
            return { ...state, Vendors: action.payload, loadingVendors: true }
        case types.GET_VENDORS_DETAILS:
            return { ...state, oneVendor: action.payload, loadingOneVen: false }
        case types.GET_CATEGORY_STORE:
            return { ...state, catStore: action.payload, loadingCatStore: false }
        case types.EMPTY_VENDORS_DETAILS:
            return { ...state, oneVendor: action.payload, loadingOneVen: true }
        case types.EMPTY_CATEGORY_STORE:
            return { ...state, catStore: action.payload, loadingCatStore: true }
        default:
            return state;
    }
};

export default VendorsReducer;