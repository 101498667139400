import React from 'react'
import logoHadyati from '../../Images/HG.gif'
import './LoadingPage.css'

function LoadingPage() {
  return (
    <div className='LoadingPage' >
        <img src={logoHadyati} />
    </div>
  )
}

export default LoadingPage