import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes } from "react-router-dom";
import Home from './Components/HomePage/Home';
import Faq from './Components/Faq/Faq';
import Privacy from './Components/Privacy/Privacy';
import Contact from './Components/Contact/Contact';
import Vendors from './Components/Vendors/Vendors';
import Terms from './Components/Terms/Terms';
import BrandDetails from './Components/BrandDetails/BrandDetails';
import Page404 from './Components/Page404/Page404';
import LoadingPage from './Components/LoadingPage/LoadingPage';


function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 2000)
  }, []);
  return (
    <div className='homee_app'>
      {
        isLoading ? (
          <LoadingPage />
        ) : (
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/faq" element={<Faq />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/brands" element={<Vendors />} />
            <Route exact path="/brands/:name" element={<BrandDetails />} />
            <Route exact path="/error" element={<Page404 />} />
            <Route exact path="*" element={<Page404 />} />
          </Routes>
        )
      }

    </div>
  )
}

export default App;

