import React, { useEffect } from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { loadContact } from '../../../Redux/Actions/ActionContact'
import peak from '../../../Images/peak.png'


function Footer() {
    let dispatch = useDispatch();
    const Contact = useSelector(state => state.ContactUs.Contact)
    const loadingContact = useSelector(state => state.ContactUs.loadingContact)
    useEffect(() => {
        dispatch(loadContact())
    }, [])
    return (
        <footer className="footer" dir='rtl' >
            <div className="GeneralContainer margin_bottom" >
                <div className='footer_row'>
                    <div className='one_footer' >
                        <h3>حولنا</h3>
                        <div className='footer_line' ></div>
                        <div className='div_link' >
                            <span>هو أول تطبيق مرخص ينظم عمليات الإهداء بين المغتربين وأهاليهم في الجمهورية العربية السورية إضافة إلى الإهداء والتسوق للقاطنين في سوريا</span>
                        </div>
                    </div>
                    <div className='one_footer' >
                        <h3>روابط سريعة</h3>
                        <div className='footer_line' ></div>
                        <div className='div_link' >
                            <a href="https://drive.google.com/u/0/uc?id=1nZPZduKAZ8IBcvciYcm5udNeaQ4ixKeT&export=download" target="_blank" className='footer_link' >تحميل مباشر</a>
                            <a target="_blank" href="https://hadyati.sy/seller/auth/sign_up" className='footer_link'>انضم لنا ك بائع</a>
                            <span className='footer_link'>جميع البائعون</span>
                        </div>
                    </div>
                    <div className='one_footer' >
                        <h3>ارقام تواصل </h3>
                        <div className='footer_line' ></div>
                        <div className='div_link' >
                            {
                                loadingContact == false ? (
                                    Contact.map((con) => {
                                        return (
                                            <div key={con.id} >
                                                <div className='num_footer' >
                                                    <i className='fa fa-phone' ></i>
                                                    <span>{con.phone1}</span>
                                                </div>
                                                <div className='num_footer' >
                                                    <i className='fa fa-phone' ></i>
                                                    <span>{con.phone2}</span>
                                                </div>
                                            </div>
                                        )
                                    })

                                ) : <></>
                            }
                        </div>

                    </div>
                    <div className='one_footer' >
                        <h3>اعدادات</h3>
                        <div className='footer_line' ></div>
                        <div className='div_link' >
                            <div className='num_footer' >
                                <i className='fas fa-shield-alt' ></i>
                                <Link className='footer_link' to="/Privacy" >سياسة الخصوصية</Link>
                            </div>
                            <div className='num_footer' >
                                <i className='fas fa-shield-alt' ></i>
                                <Link className='footer_link' to="/Terms" >الاحكام و الشروط</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <a target="_blank" href='https://site.peaklinkdemo.com/' >
                <div className='powered' >
                    <h3>Peak Link</h3>
                    <img src={peak} />
                    <h3>Powred by</h3>
                </div>
                </a>
            </div>
        </footer>
    )
}

export default Footer