import * as types from "../ActionType";

const initState = {
    Privacy: [],
    loadingPrivacy: true,
    Terms: [],
    loadingterms: true
}

const PrivacyReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_ALL_PRIVACY:
            return { ...state, Privacy: action.payload, loadingPrivacy: false }
        case types.GET_ALL_TERMS:
            return { ...state, Terms: action.payload, loadingterms: false }
        default:
            return state;
    }
};

export default PrivacyReducer;