import React from 'react'
import './Header.css'
import google from '../../../Images/google_play.svg'
import ios from '../../../Images/app_store.svg'
import Zoom from 'react-reveal/Zoom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import imm1 from '../../../Images/ee.avif'
import imm2 from '../../../Images/e-com11.jpg'
import imm3 from '../../../Images/e-com7.jpg'

function Header() {
    const moveDowm = () => {
        window.scrollTo({
            top: document.documentElement.scrollTop + 700,
            behavior: 'smooth'
        });
    }
    const settings = {
        autoPlay: true,
        infiniteLoop: true,
        showStatus: false,
        showThumbs: false
    }
    const images = [
        {
            id: 1,
            imgg: imm3
        },
        {
            id: 2,
            imgg: imm2
        },
        {
            id: 3,
            imgg: imm1
        }
    ]
    return (
        <>
            <Carousel {...settings} >
                {
                    images.map((i) => {
                        return (
                            <div key={i.id} >
                                <div className='hero_sec' style={{ background: ' linear-gradient(rgba(53, 37, 37, 0.45), rgba(0, 0, 0, 0.45)), url(' + i.imgg + ') center/cover no-repeat' }} >
                                    <Zoom duration={2000} >
                                        <div className='hero_info' >
                                            <h3>تطبيق هديتي</h3>
                                            <span>اهدي واتسوق على باب بيتك </span>
                                            <div className='div_download' >
                                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.peaklink.hadyaty" ><img className='img_download' src={google} alt="تطبيق هديتي" title="تطبيق هديتي" /></a>
                                                <img className='img_download' src={ios} />
                                            </div>
                                        </div>
                                    </Zoom>
                                </div>
                            </div>
                        )
                    })
                }
            </Carousel>
            <div className="go-down" id="move" onClick={moveDowm} >
                <i className="fas fa-angle-double-down fa-2x"></i>
            </div>
        </>
    )
}

export default Header