import * as types from "../ActionType";

const initState = {
    Contact: [],
    loadingContact : true
}

const ContactReducer = (state = initState , action) => {
    switch(action.type){
        case types.GET_ALL_CONTACT:
            return {...state , Contact: action.payload , loadingContact: false}
        default:
            return state;
    }
};

export default ContactReducer;