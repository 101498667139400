import React from 'react'
import './Page404.css'

function Page404() {
    return (
        <div class="mainbox">
            <div class="err">4</div>
            <i class="far fa-question-circle fa-spin"></i>
            <div class="err2">4</div>
            <div class="msg">الصفحة غير موجودة</div>
        </div>
    )
}

export default Page404