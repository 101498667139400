import React from 'react'
import './AboutUs.css'
import about1 from '../../../Images/h25.png'
import about2 from '../../../Images/h20.png'
import Fade from 'react-reveal/Fade';

function AboutUs() {
    return (
        <div className='div_about' dir='rtl' >
            <div className='GeneralContainer' >
                <section className="aboutt top" id="about">
                    <div className="GeneralRow">
                        <Fade right duration={2000} >
                            <div className="left">
                                <div className="img">
                                    <img src={about1} alt="متجر في تطبيق هديتي" className="image1" />
                                    <img src={about2} alt="متجر في تطبيق هديتي" className="image2" />
                                </div>
                            </div>
                        </Fade>
                        <Fade left duration={2000} >
                            <div className="right">
                                <div className="heading">
                                    <h5>الارتقاء بمستوى الراحة إلى أعلى ما يمكن</h5>
                                    <div className='mark' >
                                        <h2>مرحبا بك في تطبيق هديتي</h2>
                                    </div>
                                    <p>هو أول تطبيق مرخص ينظم عمليات الإهداء بين المغتربين وأهاليهم في الجمهورية العربية السورية إضافة إلى الإهداء والتسوق للقاطنين في سوريا
                                        ويحتوي المتجر على العديد من الميزات والاضافات التي ستغير مفهوم التجارة الالكترونية  </p>
                                    <p>خطواتك معنا إلها قيمة
                                        اهدي وتسوق ع باب البيت</p>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default AboutUs