import * as types from "../ActionType";
import axios from "axios"; 

const getFaqs = (Faqs) => {
    return ({
        type: types.GET_ALL_FAQS,
        payload: Faqs
    })
}


export const loadFaqs = () => {
    return function (dispatch) {
        axios.get("https://hadyati.sy/web/v1/api/get_faqs").then((res) => {
            //console.log(res);
            dispatch(getFaqs(res.data.data))
        }).catch((err) => console.log(err))
    }
}
